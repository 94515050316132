/* General Reset and Body Styling */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif; /* Modern, clean font */
  background-color: #f9f9f9;
  color: #333;
}

/* Header Styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #ffffff;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.header-scrolled {
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Logo Styling */
.logo {
  font-weight: 700;
  font-size: 1.5rem;
  color: #ff007f;
  padding-right: 2%;
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-link {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s;
  position: relative;
}

.nav-link:hover {
  color: #ff007f;
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: #ff007f;
  transition: width 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
  left: 0;
}

/* Cart Icon Styling */
.cart-icon {
  color: #333;
  text-decoration: none;
  font-size: 1.5rem;
  transition: color 0.3s;
  margin-left: auto;
}

.cart-icon:hover {
  color: #ff007f;
}

/* Hamburger Menu Styling */
.hamburger-menu {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: #333;
  transition: all 0.3s ease;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #ffffff;
    width: 200px;
    padding: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .nav-open {
    display: flex;
  }

  .hamburger-menu {
    display: block;
  }

  .cart-icon {
    margin-right: 1rem;
  }
}

/* Solid Line Styling */
.solid {
  border: 0;
  height: 2px;
  background-color: #e0e0e0;
  width: 90%;
  margin: 1rem 0;
}
