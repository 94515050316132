.home {
    text-align: center;
    padding: 2rem;
  }
  
  .button {
    padding: 0.5rem 1rem;
    background-color: #f4b400;
    color: white;
    border: none;
    border-radius: 5px;
    text-decoration: none;
  }
  