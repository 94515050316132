.logo-container {
    text-align: center;
    background-color: #FFF1F4; /* Light pink background color */
    padding: 20px;
    border-radius: 10px;
  }
  
  .logo-top-text {
    font-size: 1.5rem;
    font-weight: 600;
    color: #b46b92;
    letter-spacing: 0.05em;
  }
  
  .logo-main-text {
    font-size: 4rem;
    font-weight: 700;
    color: #ff007f; /* Dark pink color */
    text-shadow: 2px 2px #ff85b3;
    position: relative;
    display: inline-block;
  }
  
  .logo-triangle {
    color: #ff007f;
    position: absolute;
    top: 15%;
    right: -20px;
    font-size: 1rem;
  }
  
  .logo-bottom-text {
    font-size: 1rem;
    font-weight: 600;
    color: #ff007f;
    margin-top: 5px;
    letter-spacing: 0.05em;
  }
  