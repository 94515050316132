/* Paste the provided CSS code here */
/* General Product Section Styling */
.product-section {
  margin-top: 50px;
  padding: 80px 40px; /* Increased top and bottom padding */
  background-color: #f5f5f5;
  border-radius: 15px;
  margin: 2rem auto;
  width: 90%;
}

.featured-header {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
}

/* Product Card Styling */
.product-card {
  background-color: #ffffff;
  border-radius: 25px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease, box-shadow 0.4s ease, opacity 0.4s ease;
  text-align: center;
  margin: 0 10px;
  opacity: 0.6; /* Default opacity for all cards */
  position: relative;
  z-index: 1;
}

/* Center Card (Largest and most prominent) */
.slick-center .product-card {
  transform: scale(1.2); /* Larger scale for the center card */
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3); /* Stronger shadow */
  opacity: 1; /* Fully visible */
  z-index: 3; /* Highest z-index */
}

/* Adjacent Cards (Medium size) */
.slick-center  .slick-slide .product-card:hover,
.slick-center + .slick-slide .product-card:hover {
  transform: scale(1.2); /* Slightly smaller than the center */
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2); /* Moderate shadow */
  z-index: 2; /* Medium z-index */
}

/* Back Cards (Smallest) */
.slick-slide:not(.slick-center) .product-card:hover {
  transform: scale(1.1); /* Default size for back cards */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Minimal shadow */
  opacity: 0.5; /* Least visible */
  z-index: 0; /* Lowest z-index */
}

/* Product Card Image */
.product-card img {
  max-width: 100%;
  border-radius: 20px;
  transition: transform 0.3s ease;
}

.product-card:hover img {
  transform: scale(1.05); /* Slight zoom on hover for interactivity */
}


/* Product Card Title */
.product-card h3 {
  font-size: 1.5rem;
  color: #ff007f;
  margin: 15px 0;
  font-weight: 600;
}

/* Product Card Price */
.product-card p {
  font-size: 1.1rem;
  color: #777;
  margin-bottom: 20px;
}

.slick-list {
  overflow: visible !important; /* Prevent clipping of scaled cards */
}

.slick-track {
  display: flex; /* Ensure the track adjusts properly */
  align-items: center; /* Center items vertically */
}

.slick-slide {
  display: flex;
  justify-content: center; /* Center each slide */
}

/* Button Styling */
.button {
  background-color: #ff007f;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.button:hover {
  background-color: #e60070;
  transform: translateY(-3px);
}

/* Styling for Slick Carousel Dots */
.slick-dots li button:before {
  color: #ff007f;
}

.slick-dots li.slick-active button:before {
  color: #ff007f;
}